.news-container{
	//background: linear-gradient(270deg, rgba(22, 22, 22, 0) 57.67%, #161616 88.21%), url("../../images/BG_NEWS.png");
	//background-size: contain;
	//background-repeat: round;
	.top-menu-container {
		.ch-button-view-green_button:before {
			background: #262b33;
		}
		.ch-button-view-green_button:after{
			background: #262b33;
		}
	}
	&__table{
		max-width: 1100px;
		margin: 0 auto;
		padding: 80px 20px;
	}
	&__first-section{
		background: #161616;
		display: flex;
		padding: 70px 20px 105px 20px;
		max-width: 1100px;
		margin: 0 auto;
		flex-direction: column;
	}

	&__slider-container{
		display: grid;
		grid-template-columns: repeat(auto-fit, minmax(500px, 1fr));
		grid-gap: 40px;
		align-items: center;
		grid-gap: 40px;
		gap: 40px;
		margin-top: 70px;
		cursor: pointer;
		& > img {
			width: 450px;
			height: 250px;
		}
	}
	.news-container__slider-title {
		font-weight: bold;
		font-size: 20px;
		line-height: 25px;
		color: #fff;
	}
	.news-container__slider-description {
		font-style: normal;
		font-weight: normal;
		margin: 24px 0;
		font-size: 14px;
		color: #A8A8A8;
	}
	.news-container__user-info{
		display: flex;
		margin-top: 15px;
		.avatar {
			width: 40px;
			height: 40px;
			border-radius: 25px;
		}
		.description {
			margin-left: 15px;
			font-style: normal;
			font-weight: normal;
			font-size: 14px;
			line-height: 18px;
			color: #fff;
		}
		.date {
			opacity: 0.5;
		}
	}
}

@media screen and (max-width: 425px){
	.news-container{
		.top-menu-container{
			background: #161616;
		}
		&__first-section{
			padding: 70px 20px 105px 20px;
		}
		&__slider-container{
			grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
			& > img{
				width: 100%;
			}
		}
		&__table{
			padding: 80px 20px;
			border-bottom: none;
		}
	}
}

@media screen and (max-width: 1024px),
screen and (max-height: 768px){
	.news-container {
		&__table {
			max-width: 1024px;
		}
		&__first-section{
			max-width: 1024px;
		}
	}
}
