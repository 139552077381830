@keyframes spin {
	0% { transform: rotate(0deg); }
	100% { transform: rotate(360deg); }
}

.spinner{
	width: 20px;
	height: 20px;
	border: 3px solid #25FFCB;
	border-top: 3px solid transparent;
	border-radius: 50%;
	animation: spin .5s linear 0s infinite;
}

.loader{
	display: flex;
	justify-content: center;
	flex-direction: row;
	font-weight: 600;
	font-size: 12px;
	line-height: 20px;
	letter-spacing: 0.06em;
	text-align: center;

	.spinner{
		margin-right: 10px;
	}
}
