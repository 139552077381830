.settings-info-wrap{
	display: flex;
	flex-direction: row;
	flex: 1 1 auto;
	flex-wrap: wrap;
	gap: 40px;
	align-items: flex-start;
	justify-content: center;
	margin: 80px 0 160px 0;
	padding: 0 20px;
}

@media screen and (max-width: 700px) {
	.settings-info-wrap{
		flex-direction: column;
		align-items: center;
		gap: 80px;
	}
}
