.referral-system {
	display: flex;
	flex-direction: column;
	gap: 16px;
	width: 100%;
	max-width: 1040px;
	border: 1px solid rgba(168, 168, 168, 0.1);
	box-sizing: border-box;
	background: #121212;
	margin: 130px 40px 160px 40px;
	padding: 0 40px;

	.title {
		margin: 30px 0;
		margin-bottom: 50px;
		text-align: center;
	}

	.ref-data {
		div {
			font-size: 14px;
			margin: 2px 0;
			color: #d8d7d7;
		}

		.tokens {
			font-size: 25px;
			color: #25FFCB;
		}
	}

	.table-title {
		margin-bottom: 30px;
		text-align: center;
		font-size: 17px;
		font-weight: 400;
		color: #A8A8A8;
	}

	.refs {
		.table {
			color: #A8A8A8;
			display: flex;
			flex-direction: column;
			max-height: 270px;

			&-header {
				display: flex;

				div {
					flex: 1 1 auto;
					text-align: center;
				}
			}

			&-body {
				overflow: auto;

				&::-webkit-scrollbar {
					width: 7px;
				}

				&::-webkit-scrollbar-track {
					background: transparent;
				}

				&::-webkit-scrollbar-thumb {
					background: #FFFFFF;
					border-radius: 20px;
				}

				.table-row {
					text-align: center;
					display: flex;
					margin: 20px 0;

					div {
						width: 33.3%;
						text-align: center;
						color: #fff;
					}

					.table-cellStatus {
						color: #25FFCB;
					}
				}
			}
		}
	}

	.ref-form {
		display: flex;
		align-items: center;
		justify-content: space-between;
		align-content: center;
		flex-wrap: wrap;

		.input-container {
			display: flex;
			flex-direction: row;
		}
	}

	.ref-link {
		display: flex;
		align-items: flex-start;
		flex-direction: column;
		margin-bottom: 30px;

		p {
			margin-bottom: 20px;
			color: #A8A8A8;
			font-size: 14px;
		}

		.ch-button {
			color: #25FFCB;
			font-weight: 400;
			margin: 0;
		}
	}

	.description {
		padding: 20px;
		text-align: center;
	}

	.set-referral {
		padding: 6px 18px;
		align-self: center;
		margin-bottom: 14px;
	}

	.active-content {
		font-size: 12px;
		text-align: center;
		margin-bottom: 200px;

		span {
			color: #25FFCB;
		}
	}

	.footer-description {
		font-size: 12px;
		text-align: center;
		display: flex;
		flex-direction: column;
		justify-content: center;
		margin-top: 20px;
		margin-bottom: 50px;

		& > span {
			color: #25FFCB;
		}
	}
}

@media screen and (max-width: 700px) {
	.referral-system {
		max-width: 335px;
		padding: 0;

		.ref-link .ch-button {
			font-size: 12px;
		}

		.table {
			font-size: 12px;
		}

		.refs .table-body .table-row div {
			overflow: hidden;
		}

		.ch-button-view-underlined_link {
			margin: 20px;
		}

		.refs, .footer-description {
			display: none;
		}

		.ref-link{
			p{
				color: #A8A8A8;
				font-size: 12px;
			}
		}

		.ref-form {
			flex-direction: column-reverse;

			.ref-data {
				margin-bottom: 40px;
			}

			.input-container {
				display: flex;
				flex-direction: column;
				width: 95%;

				.ch-button {
					text-align: right;
					margin: 0;
					margin-top: 5px;
				}
			}
		}
	}
}
