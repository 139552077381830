$height: 24px;
$width: 48px;
$ball-size: 20px;
$ball-padding: 2px;

.switch-container {
	display: flex;
	justify-content: center;
	align-items: center;

	input[type=checkbox] {
		height: 0;
		width: 0;
		visibility: hidden;
	}

	label {
		cursor: pointer;
		text-indent: -9999px;
		width: $width;
		height: $height;
		background: grey;
		display: block;
		border-radius: 100px;
		position: relative;
		background: rgba(168, 168, 168, 0.1);
		border-color: rgba(168, 168, 168, 0.2);
		box-sizing: border-box;
	}

	label:after {
		content: '';
		position: absolute;
		top: $ball-padding;
		left: $ball-padding;
		width: $ball-size;
		height: $ball-size;
		background: #25FFCB;
		box-shadow: 0 2.32257px 6.19352px rgba(0, 0, 0, 0.15);
		border-radius: 90px;
		transition: 0.3s;
	}

	input:checked + label:after {
		left: $width - $ball-padding;
		transform: translateX(-100%);
	}
}
