.pricing-page {
	background: linear-gradient(270deg, rgba(22, 22, 22, 0.55) 57.67%, #161616 88.21%), url("../../images/pricing-bg.png");
	background-repeat: round;

	.footer-container {
		background: transparent;
	}

	&__first-section {
		display: flex;
		flex-direction: column;
		align-items: center;
		padding: 75px 0;
	}

	&__btnPeriod{
		margin-bottom: 50px;
		display: flex;
		align-items: center;
		.selected{
			background: #FCFF71;
			color: #262b33;
			&:hover{
				background: #FCFF71;
				color: #262b33;
			}
		}
		button:hover{
			background: radial-gradient(209.65% 266% at 50% 50%, rgba(252, 255, 113, 0) 0%, rgba(252, 255, 113, 0.547135) 99.99%);
			transition: 0.2s;
			color: #fff;
		}
		button:first-child{
			border-top-right-radius: 0;
			border-bottom-right-radius: 0;
		}
		button:last-child{
			border-left: 0;
			border-top-left-radius: 0;
			border-bottom-left-radius: 0;
		}
		button{
			cursor: pointer;
			width: 100px;
			height: 30px;
			border-radius: 4px;
			border-color: #FCFF71;
		}
	}

	&__table {
		padding: 0 20px 160px 20px;

		.divider {
			height: 40px;
		}

		table {
			border-collapse: collapse;
			margin: 0 auto;

			tr:first-child {
				th:first-child {
					border: none;
					background: none;
				}
			}

			th {
				background: rgba(255, 255, 255, 0.08);
				border: 0.5px solid #A8A8A8;
				color: #A8A8A8;
				font-style: normal;
				font-weight: normal;
				font-size: 14px;
				line-height: 160%;
				height: 40px;
			}

			th.th-active {
				background: rgba(252, 255, 113, 0.2);
				border: 0.5px solid #FCFF71;
				color: #FCFF71;
			}

			td {
				&:first-child {
					background: rgba(255, 255, 255, 0.08);
					border: 0.5px solid #A8A8A8;
					color: #A8A8A8;
					font-style: normal;
					font-weight: normal;
					font-size: 14px;
					line-height: 160%;
					text-align: left;
					padding: 9px 8px;
					width: 410px;
				}

				background: #161616;
				border: 0.5px solid #A8A8A8;
				height: 40px;
				color: #A8A8A8;
				width: 155px;
				text-align: center;
				font-style: normal;
				font-weight: normal;
				font-size: 14px;
				line-height: 160%;
			}

			td.td-active {
				border: 0.5px solid #FCFF71;
				color: #FCFF71;
			}
		}
	}

	&__priceContainer {
		display: flex;
		flex-direction: row;
		flex-wrap: wrap;
		align-items: flex-start;
		padding-bottom: 40px;
		justify-content: center;
		margin-bottom: 80px;
		margin-top: 150px;
		border-bottom: 1px solid rgba(252, 255, 113, 0.2);

		.price-card {
			margin: 10px 20px;
		}
		.price-card-active{
			margin-bottom: 0;
		}
	}
}

@media screen and (max-width: 425px) {
	.pricing-page {
		&__first-section {
			padding-bottom: 0;
		}

		&__priceContainer {
			flex-direction: column;
			align-items: center;

			.price-card {
				margin: 20px 0;
				width: 90%;

				&:nth-child(3) {
					order: -1;
				}

				&__description {
					height: auto;
					margin-bottom: 20px;
				}

				&__btnNotPrice {
					margin: 0;
				}

				&__year {
					margin-bottom: 20px;
				}
			}
		}

		&__table{
			.divider{
				display: none;
			}
			table {
				tr{
					display: flex;
					flex-wrap: wrap;
					width: 90%;
					justify-content: center;
					margin: 0 auto;
					td:first-child{
						width: 100%;
						text-align: center;
					}
					td{
						width: 25%;
						line-height: 40px;
					}
				}


				tr:first-child {
					th:first-child {
						width: 100%;
					}
				}

				th {
					width: 25%;
					line-height: 40px;
				}
			}
		}
	}
}
