.ch-button{
	background: none;
	color: white;
	border: none;
	cursor: pointer;
	font-family: 'source-code-pro', Roboto, sans-serif;
}

.ch-button .ch-button-account{
	display: flex;
	align-items: center;
	& > div{
		margin-left: 17px;
		color: #25FFCB;
		font-size: 14px;
		letter-spacing: 0.1em;
	}
	.icon{
		width: 27px;
	height: 27px;
	}
}

.ch-button.ch-button-view-link.ch-button-active{
	filter: drop-shadow(0px 0px 30px #25FFCB);
	color: #25FFCB;
	position: relative;
	&:before{
		content: '';
		width: 80%;
		height: 1px;
		background: #25FFCB;
		display: block;
		bottom: -7px;
		position: absolute;
		left: 10%;
	}
}

.ch-button-view-link{
	font-weight: 600;
	font-size: 14px;
	line-height: 18px;
	letter-spacing: 0.1em;
	color: #A8A8A8;
	margin: 0 20px;
}

.ch-button-view-green_button{
	border: 1px solid #25FFCB;
	line-height: 18px;
	font-size: 14px;
	font-weight: 600;
	color: #25FFCB;
	padding: 6px 36px;
	letter-spacing: 0.1em;
	position: relative;
	background: radial-gradient(110.71% 88.89% at 50% 50%, rgba(37, 255, 203, 0) 0%, rgba(37, 255, 203, 0.55) 100%);
	&:after{
		content: '';
		transform: matrix(0.71, -0.77, 0.65, 0.71, 0, 0);
		display: block;
		position: absolute;
		width: 12.78px;
		height: 32.68px;
		left: -13px;
		top: -24px;
		background: #14201e;
		border-bottom: 1px solid #25FFCB;
	}
	&:before{
		content: '';
		transform: matrix(0.71, -0.77, 0.65, 0.71, 0, 0);
		display: block;
		position: absolute;
		width: 12.78px;
		height: 32.68px;
		right: -13px;
		bottom: -24px;
		background: #14201e;
		border-top: 1px solid #25FFCB;
	}
}

.ch-button-view-yellow_button{
	border: 1px solid #FCFF71;
	line-height: 18px;
	font-size: 14px;
	font-weight: 600;
	color: #FCFF71;
	padding: 6px 36px;
	letter-spacing: 0.1em;
	position: relative;
	background: radial-gradient(110.71% 88.89% at 50% 50%, rgba(252, 255, 113, 0) 0%, rgba(252, 255, 113, 0.55) 100%);
	&:after{
		content: '';
		transform: matrix(0.71, -0.77, 0.65, 0.71, 0, 0);
		display: block;
		position: absolute;
		width: 12.78px;
		height: 32.68px;
		left: -13px;
		top: -24px;
		background: #212427;
		border-bottom: 1px solid #FCFF71;
	}
	&:before{
		content: '';
		transform: matrix(0.71, -0.77, 0.65, 0.71, 0, 0);
		display: block;
		position: absolute;
		width: 12.78px;
		height: 32.68px;
		right: -13px;
		bottom: -24px;
		background: #212427;
		border-top: 1px solid #FCFF71;
	}
}

.ch-button-view-box_green {
	padding: 6px 15px;
	opacity: 0.3;
	border: 1px solid #FFFFFF;
	box-sizing: border-box;
	font-style: normal;
	font-weight: 600;
	font-size: 12px;
	line-height: 15px;
	letter-spacing: 0.06em;
	color: #FFFFFF;

	&.ch-button-active{
		color: #25FFCB;
		opacity: 1;
		background: rgba(37, 255, 203, 0.15);
		border: 1px solid #25FFCB;
	}
}

.ch-button-view-underlined_link {
	font-style: normal;
	font-weight: normal;
	font-size: 14px;
	line-height: 160%;
	text-decoration-line: underline;
	color: #A8A8A8;
	opacity: 0.8;
}
