.learn-container{
	//background: linear-gradient(270deg, rgba(22, 22, 22, 0) 57.67%, #161616 88.21%), url("../../images/LEARNING_BG.png");
	//background-size: contain;
	//background-repeat: round;

	&__section, &__table {
		padding: 80px 20px;
		max-width: 1100px;
		margin: 0 auto;
	}

	&__section{

		.title-text{
			display: flex;
			justify-content: center;
			align-items: center;
			gap: 10px;
			padding-bottom: 30px;
		}

		.under-title-text{
			font-style: normal;
			font-weight: normal;
			font-size: 16px;
			line-height: 160%;
			text-align: center;
			color: #A8A8A8;
			padding-bottom: 100px;
		}

		.button-set{
			padding-top: 40px;
			display: flex;
			flex-direction: row;
			justify-content: center;
			flex-wrap: wrap;
			gap: 15px;
		}
	}

	&__table{
		padding-bottom: 150px;
	}
}

@media screen and (max-width: 425px){
	.learn-container{

		.title-text{
			flex-direction: column;
		}

		&__section{
			padding: 80px 10%;

			.button-set{
				gap: 10px;
			}
		}

		&__table{
			padding: 0 20px 150px 20px;
		}
	}
}

@media screen and (max-width: 1024px),
screen and (max-height: 768px) {
	.learn-container {
		&__section, &__table {
			max-width: 1024px;
		}
	}
}
