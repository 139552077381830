.home-page{
	display: flex;
	flex-direction: column;
	&__first-section {
	background-image: radial-gradient(70.06% 88.89% at 50% 50%, rgba(37, 255, 203, 0.22) 0%, rgba(37, 255, 203, 0.03) 57.81%), url("../../images/Hero image.png");
	background-repeat: round;
	background-size: contain;
   }
	&__learn-trade-title{
		width: 100%;
		display: flex;
		justify-content: center;
		margin-top: 20%;
		flex-direction: column;
		align-items: center;
		padding-bottom: 15%;
		.ch-button{
			display: none;
		}
	}
	&__navigate-title{
		font-style: normal;
		font-weight: normal;
		font-size: 24px;
		line-height: 30px;
		color: #fff;
		margin-top: 45px;
	}
	&__divider-container {
		display: flex;
		align-items: flex-start;
		flex-direction: row;
		justify-content: center;
		div{
			color: #fff;
			opacity: 0.5;
		}
		.item {
			opacity: 1;
			display: flex;
			margin: 0 185px;
		}
		.item > img{
			width: 17px;
			height: 16px;
			margin: 0 15px;
		}
		.item > .divider {
			width: 1px;
			height: 158px;
			background: #A8A8A8;
		}
	}

	&__second-section{
		background-image: linear-gradient(180deg, rgba(22, 22, 22, 0.5) 65.38%, #161616 100%), url("../../images/bg.png");
		background-repeat: round;
		background-size: contain;
		&--container{
			display: grid;
			grid-template-columns: repeat(auto-fit,minmax(500px,1fr));
			grid-gap: 40px;
			align-items: center;
			gap: 40px;
			margin: 40px 0;
			max-width: 1100px;
			margin: 0 auto;
			padding: 0 20px;
			.firstImg{
				background-image: url("../../images/first-image.png");
				width: 535px;
				height: 535px;
				background-repeat: no-repeat;
			}

			.ch-button.ch-button-view-link{
				margin: 0;
			}

			.secondImg{
				background-image: url("../../images/second-image.png");
				width: 600px;
				height: 440px;
				background-repeat: no-repeat;
			}
		}
		&--title{
			font-style: normal;
			font-weight: normal;
			font-size: 40px;
			line-height: 46px;
			font-family: 'Airborne';
			color: #fff;
			text-transform: uppercase;
		}
		&--description{
			font-style: normal;
			font-weight: normal;
			font-size: 14px;
			color: #A8A8A8;
			margin: 40px 0;
		}

		&--second-container-title{
			font-style: normal;
			font-weight: normal;
			font-size: 32px;
			color: #fff;
			font-family: 'Airborne';
			text-transform: uppercase;
			max-width: 1100px;
			padding: 0 20px;
			margin: 100px auto 200px auto;
		}
	}

	&__last-section--container{
		max-width: 1100px;
		margin: 0 auto;
		padding: 0 20px;
		.btn-container{
			display: flex;
			align-items: center;
			justify-content: center;
			margin-bottom: 200px;
			flex-wrap: wrap;
			img{
				cursor: pointer;
			}
		}
		&--title{
			font-size: 40px;
			line-height: 46px;
			text-align: center;
			letter-spacing: 0.04em;
			font-family: 'Airborne';
			color: #fff;
			text-transform: uppercase;
		}
		&--description{
			font-style: normal;
			font-weight: normal;
			font-size: 14px;
			color: #A8A8A8;
			width: 70%;
			margin: 40px auto;
		}
	}
}

@media screen and (max-width: 1024px),
screen and (max-height: 768px){
	.home-page{
		&__second-section {
			&--container {
				max-width: 1024px;
			}
			&--second-container-title{
				max-width: 1024px;
			}
		}
		&__last-section--container {
			max-width: 1024px;
		}
	}
}

@media screen and (max-width: 425px){
	.home-page {
		&__first-section {
			background-image: radial-gradient(213.2% 88.89% at 50% 50%, rgba(37, 255, 203, 0.22) 0%, rgba(37, 255, 203, 0.03) 56.39%), url("../../images/Hero-image-small.png");
			.top-menu-container{
				margin-top: 28px;
				padding: 0 20px;
			}
			.ch-button-view-link{
				display: none;
			}
		}
		&__learn-trade-title{
			margin: 180px 0;
			padding: 0;
			.title-page-large{
				font-size: 40px;
				line-height: 46px;
				letter-spacing: 0.04em;
				.title-page__item {
					& > div:first-child {
						height: 24px;
					}
				}
			}
			& > .ch-button{
				display: block;
			}
		}
		&__navigate-title{
			font-size: 14px;
			margin-bottom: 32px;
		}

		&__divider-container{
			.item{
				margin: 0 45px;
				.divider{
					height: 100px;
				}
			}
		}

		&__second-section {
			background-repeat: no-repeat;
			background-size: cover;
			&--title{
				font-size: 32px;
			}
			&--second-container-title{
				font-size: 20px;
			}
			&--container {
				grid-template-columns: repeat(auto-fit, minmax(290px, 1fr));
				margin-top: 0;
				margin-bottom: 150px;

				.firstImg {
					background-image: url("../../images/first-image-small.png");
					width: 95%;
					height: 350px;
					background-position: center;
				}

				.ch-button.ch-button-view-link{
					width: 100%;
					margin: 0 auto;
				}

				.secondImg {
					grid-row: 1;
					background-image: url("../../images/second-image-small.png");
					width: 95%;
					height: 350px;
				}

			}
		}

		&__last-section--container {
			.btn-container {
				flex-direction: column;
				img{
					margin: 16px 0;
				}
			}

			&--description{
				width: 100%;
				text-align: center;
			}
		}
	}
}
