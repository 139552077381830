.border-button{
	display: flex;
	align-items: center;
	justify-content: center;
	position: relative;
	.icon{
		color: rgba(168, 168, 168, 0.22);
		height: 32px;
		width: 140px;
	}
	div{
		position: absolute;
		color: #A8A8A8;
		font-size: 14px;
		line-height: 18px;
		letter-spacing: 0.1em;
	}
	&-children{
		pointer-events: none;
	}
}

.border-button-yellow{
	.icon{
		color: rgba(252, 255, 113, 0.22);
	}
	div{
		color: #FCFF71;
	}
}

.border-button-green{
	.icon{
		color: rgba(37, 255, 203, 0.22);
	}
	div{
		color: #25FFCB;
	}
}

.border-button-large{
	.icon{
		width: 320px;
		height: 40px;
	}
}

.border-button-disabled{
	cursor: not-allowed;
}
