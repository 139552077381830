.menu-drop-down{
	position: relative;
	margin-left: 15px;
	border-radius: 6px 6px 0 0;
	.drop-down{
		background: #101114;
		min-width: 125px;
		max-width: 250px;
		opacity: 0;
		transform: scale(0);
		position: absolute;
		transition: all .3s cubic-bezier(.16,1,.3,1);
		transition-delay: .15s;
		transition-property: transform,transform-origin,opacity;
		transform-origin: 100% 0;
		top: 27px;
		right: 0;
		border-radius: 6px 0 6px 6px;
		display: flex;
		align-items: center;
		justify-content: center;
		flex-direction: column;
		z-index: 9999;
		padding: 10px;
		& > button{
			&:first-child{
				border-bottom: none;
				padding-top: 0;
				border-top: 0 !important;
			}
			&:last-child{
				color: #fe4358;
				border-bottom: 0;
				border-top: 1px solid #24262d;
				&:hover{
					text-decoration: underline;
				}
			}
			text-align: right;
			font-size: 14px;
			margin: 10px 0;
			width: 100%;
			margin-bottom: 0;
			padding-top: 10px;
			&:hover{
				text-decoration: underline;
			}
			&:first-child{
				margin-top: 0;
			}
		}
	}
}

.menu-drop-down-visible{
	background: #101114;
	& > img{
		transform: rotate(180deg);
	}
	.drop-down{
		display: flex;
		transform: scale(1);
		opacity: 1;
		transition-delay: 0s;
	}
}
