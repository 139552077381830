.swiper__container {
	display: flex;
	flex-direction: row;
	justify-content: space-evenly;
	.ch-button {
		width: 100%;
		font-style: normal;
		font-weight: normal;
		font-size: 24px;
		line-height: 30px;
		text-align: center;
		color: #FFFFFF;
		padding: 16px 0;
		mix-blend-mode: normal;
		opacity: 0.3;
		border-bottom: 1px solid #25FFCB;
		cursor: pointer;

		&.ch-button-active{
			opacity: 1;
			border-bottom: 1px solid #25FFCB;
		}
	}
}

@media screen and (max-width: 600px){
	.swiper__container{
		.ch-button {
			font-style: normal;
			font-weight: 600;
			font-size: 14px;
			line-height: 18px;
			text-align: center;
			letter-spacing: 0.1em;
		}
	}
}
