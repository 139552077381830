.article-page{
	//background: linear-gradient(270deg, rgba(22, 22, 22, 0.5) 57.67%, #161616 88.21%), url("../../images/BG_NEWS.png");
	//background-size: contain;
	.ch-button-view-green_button:after, .ch-button-view-green_button:before {
		background: #1e2025;
	}

	.second-section{
		.footer-container{
			background: linear-gradient(270deg, rgba(22, 22, 22, 0.5) 57.67%, #161616 88.21%), url("../../images/bg.png");
		}
	}
}
