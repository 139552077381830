$screen-content-width: 1040px;

.account-setting{
	background: #161616;

	&__section{
		display: grid;
		place-content: center;
		padding-top: 90px;

		.title-text{
			display: flex;
			justify-content: center;
			align-items: center;
			gap: 10px;
			padding-bottom: 150px;
		}
		.swiper__container{
			display: flex;
			button {
				width: 226px;
			}
		}
	}
	.footer-container{
		background: #161616;
	}
}

@media screen and (max-width: 700px) {
	.account-setting{
		&__section{
			.title-text{
				flex-direction: column;
				padding-bottom: 70px;
			}
			.swiper__container button{
				width: auto;
				font-size: 14px;
				font-weight: 600;
			}
		}
	}
}
