.settings-info{
	display: flex;
	flex-direction: column;
	gap: 16px;
	max-width: 500px;
	width: 100%;
	border: 1px solid rgba(168, 168, 168, 0.1);
	box-sizing: border-box;
	padding: 20px;
	background: #121212;

	.title{
		align-self: center;
		font-family: source-code-pro, Roboto, sans-serif;
		font-style: normal;
		font-weight: bold;
		font-size: 20px;
		line-height: 25px;
		text-align: center;
		padding-bottom: 4px;
	}

	.foreword{
		font-family: source-code-pro, Roboto, sans-serif;
		font-style: normal;
		font-weight: normal;
		font-size: 14px;
		line-height: 160%;
		text-align: center;
		color: #A8A8A8;

		.ch-button{
			margin: 0;
			padding-left: 10px;
			font-weight: normal;
			font-size: 14px;
			line-height: 160%;
			text-align: center;
			color: #25FFCB;
		}
	}

	.input-wrap{
		display: grid;
		grid-template-columns: 2fr 1fr;
		gap: 16px;

		.ch-button{
			margin: 0;
			text-align: left;
		}
	}
}

@media screen and (max-width: 700px) {
	.settings-info{
		max-width: 335px;

		.title{
			padding-bottom: 32px;
		}

		.input-wrap{
			grid-template-columns: 1fr;
			grid-template-rows: 1fr auto;
			gap: 8px;

			.ch-button{
				height: 22px;
				text-align: end;
			}
		}

		.foreword{
			padding-top: 16px;
		}
	}
}
