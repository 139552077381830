.about-page{
	background: #161616;
	&__content{
		display: flex;
		flex-direction: column;
		align-items: center;
		padding: 75px 20%;
		padding-bottom: 160px;
	}
	&__title{
		font-weight: bold;
		font-size: 20px;
		line-height: 25px;
		color: #fff;
		margin-top: 150px;
		text-align: center;
	}
	&__description{
		font-style: normal;
		font-weight: normal;
		font-size: 14px;
		text-align: center;
		color: #A8A8A8;
		margin: 24px 0 80px 0;
		line-height: 160%;
	}
	&__updatesTitle{
		font-weight: bold;
		font-size: 20px;
		line-height: 25px;
		color: #fff;
		margin-bottom: 24px;
		text-align: center;
	}
	&__updatesDescription{
		font-style: normal;
		font-weight: normal;
		font-size: 14px;
		text-align: center;
		margin-bottom: 80px;
		line-height: 160%;
		color: #A8A8A8;
		.link{
			color: #25FFCB;
			cursor: pointer;
		}
	}
	&__contactTitle{
		font-style: normal;
		font-weight: bold;
		font-size: 20px;
		line-height: 25px;
		color: #fff;
	}
	.footer-container{
		background: #161616;
	}
}

@media screen and (max-width: 425px){
	.about-page {
		&__content{
			padding: 75px 20px;
			padding-bottom: 160px;
		}
		&__title {
			margin-top: 70px;
		}
	}
}
