.check-email{
	background: #161616;
	height: 100vh;
	display: grid;
	grid-template-rows: 1fr 300px;

	.footer-container{
		background: #161616;
	}

	&__container{
		padding-top: 150px;
		display: grid;
		justify-content: center;
		align-items: baseline;
		border-bottom: 1px solid #FCFF71;
	}

	&__content{
		display: grid;
		grid-template-rows: auto auto auto;
		grid-gap: 16px;
		position: relative;
		width: 320px;

		.arrow-back{
			position: absolute;
			top: -15px;
			left: -100px;
			opacity: 0.15;
		}
		.title{
			font-family: Airborne, source-code-pro, Roboto, sans-serif;
			font-style: normal;
			font-weight: normal;
			font-size: 32px;
			line-height: 160%;
			text-align: center;
			letter-spacing: 0.04em;
		}
		.foreword{
			font-family: source-code-pro, Roboto, sans-serif;
			font-style: normal;
			font-weight: normal;
			font-size: 14px;
			line-height: 160%;
			text-align: center;
			color: #A8A8A8;
		}
		.checked{
			height: 20px;
			width: 24px;
			margin: auto;
		}
	}
}

@media screen and (max-width: 850px),
		 screen and (max-height: 700px){
	.check-email{
		grid-template-rows: 1fr;

		.arrow-back{
			position: absolute;
			top: 10%;
			left: 10%;
			height: 23px;
			width: 23px;
		}
		&__content{
			width: 100%;
			padding: 0 10%;
			position: initial;
		}
		&__container{
			border-bottom: none;
			place-content: center;
			padding: 0;
		}
		.footer-container{
			display: none;
		}
	}
}
