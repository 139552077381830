@font-face {
	font-family: 'source-code-pro';
	font-weight: normal;
	src: url(SourceCodePro/SourceCodePro-Regular.ttf) format('opentype');
}
@font-face {
	font-family: 'source-code-pro-bold';
	font-weight: bold;
	src: url(SourceCodePro/SourceCodePro-Bold.ttf) format('opentype');
}
@font-face {
	font-family: 'source-code-pro-semi-bold';
	font-weight: 300;
	src: url(SourceCodePro/SourceCodePro-SemiBold.ttf) format('opentype');
}

@font-face {
	font-family: 'Airborne';
	font-weight: 600;
	src: url(Airborne/airborne.ttf) format('opentype');
}
