.verification-password{
	background: #161616;
	height: 100vh;
	display: grid;
	grid-template-rows: 1fr 300px;

	.footer-container{
		background: #161616;
	}

	&__container{
		padding-top: 150px;
		display: grid;
		justify-content: center;
		align-items: baseline;
		border-bottom: 1px solid #FCFF71;
	}

	&__content{
		display: grid;
		grid-template-rows: auto auto auto auto 40px;
		grid-gap: 16px;
		position: relative;
		width: 400px;

		.react-code-input{
			text-align: center;
			input{
				&:focus-visible{
					outline: none !important;
				}
				width: 66px !important;
				height: 60px !important;
				background: transparent !important;
				border-color: #393939 !important;
				border-radius: 0 !important;
				font-size: 50px !important;
				color: #fff !important;
			}
		}

		.server-errors{
			&__container{
				display: grid;
				place-items: center;
			}
			&__content{
				color: #FCFF71;
				opacity: 0.5;
				font-weight: 600;
				font-size: 12px;
				line-height: 15px;
				letter-spacing: 0.06em;
				text-align: center;
			}
		}

		.arrow-back{
			position: absolute;
			top: -15px;
			left: -100px;
			opacity: 0.15;
		}

		.title {
			font-family: Airborne, source-code-pro, Roboto, sans-serif;
			font-size: 32px;
			line-height: 160%;
			text-align: center;
			letter-spacing: 0.04em;
			color: white;
		}
		.foreword{
			font-size: 14px;
			line-height: 22.4px;
			font-weight: 400;
			text-align: center;
			color: #A8A8A8;
		}
		& .ch-button{
			margin: 0;
			color: #25FFCB;
			line-height: 160%;
		}
	}
}

@media screen and (max-width: 850px), screen and (max-height: 750px){
	.verification-password{
		&__container{
			border-bottom: none;
		}
		.footer-container{
			display: none;
		}
	}
}
