.account-setting-upgrade{
	display: flex;
	align-items: center;
	flex-direction: column;

	&__btnPeriod{
		margin-bottom: 50px;
		display: flex;
		align-items: center;
		.selected{
			background: #FCFF71;
			color: #262b33;
			&:hover{
				background: #FCFF71;
				color: #262b33;
			}
		}
		button:hover{
			background: radial-gradient(209.65% 266% at 50% 50%, rgba(252, 255, 113, 0) 0%, rgba(252, 255, 113, 0.547135) 99.99%);
			transition: 0.2s;
			color: #fff;
		}
		button:first-child{
			border-top-right-radius: 0;
			border-bottom-right-radius: 0;
		}
		button:last-child{
			border-left: 0;
			border-top-left-radius: 0;
			border-bottom-left-radius: 0;
		}
		button{
			cursor: pointer;
			width: 100px;
			height: 30px;
			border-radius: 4px;
			border-color: #FCFF71;
		}
	}

	&__title{
		margin: 80px 0;
		font-weight: bold;
		font-size: 20px;
		line-height: 25px;
		color: #fff;
		text-align: center;
	}
	&__priceContainer {
		display: flex;
		flex-direction: row;
		align-items: flex-start;
		flex-wrap: wrap;
		justify-content: center;
		padding-bottom: 40px;
		margin-bottom: 160px;

		.price-card {
			margin: 10px 20px;
		}
		.price-card-active{
			margin-bottom: 0;
		}
	}
}

@media screen and (max-width: 425px) {
	.account-setting-upgrade {

		&__priceContainer {
			flex-direction: column;
			align-items: center;

			.price-card {
				margin: 20px 0;
				width: 90%;

				&:nth-child(3) {
					order: -1;
				}

				&__description {
					height: auto;
					margin-bottom: 20px;
				}

				&__btnNotPrice {
					margin: 0;
				}

				&__year {
					margin-bottom: 20px;
				}
			}
		}
	}
}
