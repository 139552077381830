.top-menu-container{
	display: flex;
	justify-content: space-around;
	max-width: 1024px;
	margin: 0 auto;
	align-items: center;
	height: 64px;
	.hamburger-btn{
		width: 32px;
		height: 32px;
		margin-right: 25px;
		display: none;
	}
	.container-button{
		display: flex;
		align-items: center;
		.ch-button-account > div{
			display: none;
		}
		button:nth-child(7){
			display: none;
		}
		button > .ch-button-account, button:last-child{
			display: none;
		}
		button svg#arrow-up {
			display: none;
		}
			.close{
			display: none;
		}
		.ch-button.ch-button-view-green_button{
			display: none;
		}
	}
	.sign-in-btn{
		display: flex;
		align-items: center;

		.icons-auth-success{
			display: flex;
			align-items: center;
			& > .ch-button{
				min-width: auto;
				margin: 0;
				margin-right: -7px;
			}
		}
		button:first-child:before{
			display: none;
		}
		& > .ch-button{
			display: flex;
			align-items: center;
			svg#arrow-up{
				width: 24px;
				height: 24px;
			}
		}
		.ch-button-account > div{
			display: none;
		}
	}
}

@media screen and (max-width: 425px){
	.top-menu-container{
		padding: 0 20px;
		justify-content: space-between;
		& > .sign-in-btn{
			display: none;
		}
		.visible-modal{
			display: flex;
			flex-direction: column;
			align-items: center;
			background: #161616;
			position: fixed;
			top: 0;
			bottom: 0;
			z-index: 9999;
			left: 0;
			right: 0;
			.close{
				width: 40px;
				height: 40px;
				margin: 20px 20px 0 auto;
			}
			.ch-button-account{
				margin-left: 0;
			}
			.ch-button.ch-button-view-link{
				display: block;
				font-family: 'Airborne', Roboto, sans-serif;
				color: #fff;
				font-size: 20px;
				margin: 16px 0;
				line-height: 24px;
			}
			.ch-button.ch-button-view-link.ch-button-active{
				color: #25FFCB;
			}
			.ch-button.ch-button-view-green_button{
				display: block;
				width: 158px;
				margin-top: 80px;
			}
		}
		.ch-button-view-green_button{
			display: none;
		}
		.container-button{
			display: none;
		}
		.hamburger-btn{
			width: 32px;
			height: 32px;
			margin-right: 25px;
			display: block;
		}
	}
}

.footer-container{
	padding: 40px 20px 70px 20px;
	background: linear-gradient(180deg, rgba(22, 22, 22, 0) 65.38%, #161616 100%), url("../../images/bg.png");
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	border-top: 1px solid rgba(252, 255, 113, 0.2);
	&__chain-view{
		display: none;
		color: #A8A8A8;
		font-size: 24px;
		text-align: center;
	}
	&__title{
		font-style: normal;
		font-weight: 600;
		font-size: 24px;
		line-height: 30px;
		letter-spacing: 0.04em;
		text-transform: uppercase;
		color: #fff;
		margin-bottom: 4px;
	}
	&__link-info{
		display: flex;
		align-items: center;
		flex-direction: row;
		margin: 12px 0;
		& > img{
			width: 17px;
			height: 16px;
			margin: 0 30px;
		}
		& > .link{
			font-style: normal;
			font-weight: normal;
			font-size: 12px;
			line-height: 15px;
			color: #fff;
			margin: 0 20px;
			cursor: pointer;
			opacity: 0.5;
		}
	}

	&__description{
		font-style: normal;
		font-weight: normal;
		font-size: 12px;
		line-height: 15px;
		margin: 12px 0;
		text-align: center;
		color: #fff;
		opacity: 0.5;
	}
}

@media screen and (max-width: 425px){
	.footer-container{
		padding: 40px 0 60px 0;
		background: #161616;
		border-top: 1px solid rgba(252, 255, 113, 0.2);
		&__chain-view{
			display: block;
		}
		&__title{
			display: none;
		}

		&__link-info-text{
			flex-direction: column;
			margin: 0;
			& > .link{
				margin: 20px 0;
				font-size: 14px;
			}
		}

		&__link-info-text{
			opacity: 0.5;

			& > img:nth-child(3), & > img:nth-child(4){
				order: -1;
			}
		}

		&__description{
			opacity: 0.5;
		}
	}
}

.article-list{
	&__columns{
		display: grid;
		grid-template-columns: repeat(auto-fit, minmax(290px, 1fr));
		grid-gap: 40px;
		gap: 40px;
		row-gap: 100px;
	}
	&__item-bg {
		background: #202020;
		padding: 0 16px 16px 16px;
		.article-list__item--title{
			text-align: left;
		}
	}
	&__item{
		display: flex;
		flex-direction: column;
		cursor: pointer;
		&--title {
			font-style: normal;
			font-weight: bold;
			font-size: 20px;
			line-height: 25px;
			margin: 15px 0;
			color: #fff;
		}
		img {
			height: 180px;
			object-fit: cover;
		}

		&--description {
			color: #A8A8A8;
			font-style: normal;
			font-weight: normal;
			font-size: 14px;
		}

		&--user-info {
			display: flex;
			margin-top: 15px;
			.avatar {
				width: 40px;
				height: 40px;
				border-radius: 25px;
			}
			.description {
				margin-left: 15px;
				font-style: normal;
				font-weight: normal;
				font-size: 14px;
				line-height: 18px;
				color: #fff;
			}
			.date {
				opacity: 0.5;
			}
		}

	}

}

.article-container {
	&__blockchain{
		color: #FCFF71;
		text-align: center;
		margin: 120px 0 25px 0;
	}

	&__title{
		font-style: normal;
		font-weight: normal;
		font-size: 40px;
		color: #fff;
		text-align: center;
		margin-bottom: 24px;
		font-family: "Airborne", Roboto, sans-serif;
		text-transform: uppercase;
	}

	&__imgAuthor{
		display: flex;
		align-items: center;
		flex-direction: row;
		.author-title {
			font-style: normal;
			font-weight: normal;
			font-size: 14px;
			color: #fff;
			margin-left: 16px;
		}
	}

	&__author{
		display: flex;
		justify-content: center;
		align-items: center;
		margin-bottom: 120px;
		img{
			width: 40px;
			height: 40px;
			border-radius: 50px;
		}
		.date-container{
			display: flex;
			flex-direction: row;
		}
		.date{
			font-style: normal;
			font-weight: normal;
			font-size: 14px;
			color: #fff;
			opacity: 0.5;
			margin-left: 35px;
			position: relative;
			&:before{
				content: '';
				width: 4px;
				height: 4px;
				display: block;
				background: #fff;
				border-radius: 50px;
				position: absolute;
				top: 50%;
				left: -20px;
			}
		}
	}

	&__first-section{
		background: #161616;
		box-shadow: 0 2px 10px rgba(0, 0, 0, 15);
		color: #A8A8A8;
		padding: 40px 100px;
		max-width: 1240px;
		margin: 0 auto;
		margin-bottom: 80px;
		&--content{
			display: flex;
			flex-direction: row;
			.left{
				width: calc(100% - 320px);
				padding-right: 30px;
				h2:first-child{
					margin-top: 0;
				}
				h2, h1{
					margin: 24px 0;
				}
				p{
					font-size: 16px;
					color: #A8A8A8;
					img{
						margin: 24px 0;
						margin-top: 0;
						width: 100%;
					}
				}
				blockquote{
					position: relative;
					margin: 30px 0;
					&:after{
						content: '';
						width: 6px;
						background: #FCFF71;
						display: block;
						height: 100%;
						position: absolute;
						top: 0;
					}
					& > p{
						padding: 5px 0 5px 50px;
						font-style: italic;
						font-weight: bold;
						line-height: 160%;
					}
				}
				.content-text{
					margin: 25px 0;
				}
				.btn-container .btn{
					background: radial-gradient(110.71% 88.89% at 50% 50%, rgba(252, 255, 113, 0) 0%, rgba(252, 255, 113, 0.29) 100%);
					border: 1px solid #FCFF71;
					color: #FCFF71;
					padding: 7px 18px;
					font-size: 14px;
					letter-spacing: 0.1em;
					margin-right: 16px;
					margin-bottom: 10px;
				}
			}
			.right {
				width: 320px;

				.article-list__columns{
					grid-row-gap: 24px;
				}

				.title{
					color: #FCFF71;
					font-size: 20px;
					font-weight: bold;
					text-align: left;
					margin-bottom: 15px;
				}
			}

		}
	}

	&__divider{
		width: 100%;
		height: 1px;
		background: #FCFF71;
		opacity: 0.2;
		margin: 40px 0;
	}

	&__content{
		.left{
			.up-text{
				font-size: 14px;
				display: flex;
				flex-direction: row;
				gap: 10px;

				button{
					border: none;
					background: transparent;
					color: #A8A8A8;
					cursor: pointer;
				}
			}
			.up-description {
				font-size: 20px;
				font-weight: bold;
				margin-top: 8px;
			}
		}
	}

	.article-list .columns{
		grid-row-gap: 25px;
		display: grid;
	}
}

@media screen and (max-width: 425px){
	.article-container{
		&__divider{
			background: transparent;
			margin: 20px 0;
		}
		&__first-section{
			padding: 40px 20px;
			margin: 0 20px 80px;
			&--content{
				flex-direction: column;
				.left{
					width: auto;
					padding-bottom: 30px;
					border-bottom: 1px solid rgba(252, 255, 113, 0.2);
					margin-bottom: 25px;
					padding-right: 0;
					img{
						width: 100%;
					}

				.btn-container {
					width: 100%;
					display: flex;

					.btn {
						padding: 7px 4px;
					}
				 }
				}
			}
		}

		&__blockchain{
			font-size: 14px;
		}
		&__title{
			font-size: 20px;
		}
		&__author{
			flex-direction: column;
			.date-container{
				.date:first-child:before{
					content: none;
				}
			}
		}
		&__imgAuthor{
			margin-bottom: 20px;
		}
	}
}

.price-card.price-card-active{
	background: radial-gradient(209.65% 266% at 50% 50%, rgba(252, 255, 113, 0) 0%, rgba(252, 255, 113, 0.547135) 99.99%);
	border: 1.1px solid #FCFF71;
	.price-card__title{
		color: #FCFF71;
	}
	.price-card__btn{
		margin-bottom: 37px;
		.border-button{
			svg.icon{
				height: 35px;
				width: 155px;
			}
		}
	}
}


.price-card{
	padding: 30px;
	cursor: pointer;
	border: 1px solid #979797;
	text-align: center;
	width: 230px;
	&__title{
		font-family: "Airborne", Roboto, sans-serif;
		font-style: normal;
		font-weight: normal;
		font-size: 32px;
		line-height: 160%;
		margin-bottom: 17px;
		color: #fff;
	}
	&__description{
		font-style: normal;
		font-weight: normal;
		font-size: 14px;
		line-height: 160%;
		color: #A8A8A8;
		height: 124px;
	}
	&__price{
		font-style: normal;
		font-weight: normal;
		font-size: 24px;
		line-height: 30px;
		color: #fff;
		margin-bottom: 8px;
		min-height: 30px;
	}
	&__year{
		font-style: normal;
		font-weight: normal;
		font-size: 14px;
		line-height: 160%;
		color: #A8A8A8;
		height: 22px;
		margin-bottom: 52px;
	}
	&__btn{
		cursor: pointer;
		margin-top: 50px;
	}
	&__details{
		font-weight: normal;
		font-size: 15.4px;
		line-height: 160%;
		color: #A8A8A8;
		text-decoration-line: underline;
		margin-top: 5px;
		background: transparent;
		border: none;
		cursor: pointer;
	}
}

.pagination-container{
	display: flex;
	justify-content: center;
	align-items: center;
	margin-top: 100px;
	.ch-button.ch-button-view-link.ch-button-active{
		min-width: auto;
		opacity: 1;
		color: #FCFF71;
		&:before{
			width: 20px;
			background: #FCFF71;
			left: -6px;
			bottom: -9px;
		}
	}
	.ch-button.ch-button-view-link{
		color: #fff;
		opacity: 0.7;
	}
}


