.title-page {
	display: flex;
	align-items: center;
	justify-content: center;
	flex-wrap: wrap;
	font-family: 'Airborne';
	font-size: 40px;
	user-select: none;
	&__item {
		margin: 0 7px;
		& > div:first-child {
			height: 20px;
			overflow: hidden;
		}

		& > div:last-child {
			height: 24px;
			overflow: hidden;
			line-height: 0;
			margin-left: 4px;
			margin-top: 3px;
		}
	}
}


.title-page-large {
	font-size: 56px;
	line-height: 64px;
	letter-spacing: 0.1em;
	.title-page__item {
		& > div:first-child {
			height: 34px;
		}
		& > div:last-child{
			margin-left: 7px;
		}
	}
}


