@mixin text($family: source-code-pro, $weight: normal) {
	font-family: $family, Roboto, sans-serif;
	font-style: normal;
	font-weight: $weight;
	text-align: center;
}

.settings-plan{
	display: flex;
	flex-direction: column;
	max-width: 500px;
	width: 100%;
	border: 1px solid rgba(168, 168, 168, 0.1);
	box-sizing: border-box;
	padding: 20px;
	background: #121212;
	flex: 1 1 auto;

	.ref-invite{
		text-align: center;
		font-size: 14px;
		color: #fff;
	}

	.title{
		align-self: center;
		@include text(source-code-pro, bold);
		font-size: 20px;
		line-height: 25px;
		padding-bottom: 55px;
	}

	.foreword{
		@include text(Airborne);
		font-size: 32px;
		line-height: 160%;
		letter-spacing: 0.04em;
		padding-bottom: 15px;
	}
	.renews{
		@include text();
		font-size: 14px;
		line-height: 160%;
		color: #A8A8A8;
		padding-bottom: 15px;
	}
	.auto-renew{
		@include text();
		font-size: 14px;
		line-height: 160%;
		display: flex;
		justify-content: center;
		align-items: center;
		gap: 30px;
		padding-bottom: 25px;
	}
	.change-plan{
		padding: 6px 18px;
		align-self: center;
		margin-bottom: 14px;
	}
	.billing-portal{
		padding: 16px 0;
		color: #A8A8A8;
		text-decoration: none;
		text-align: center;
	}
}

@media screen and (max-width: 700px) {
	.settings-plan{
		max-width: 335px;

		.billing-portal{
			padding-bottom: 30px;
		}
	}
}
