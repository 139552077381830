.sign-in{
	background: #161616;
	height: 100vh;
	display: grid;
	grid-template-rows: 1fr 300px;

	.footer-container{
		background: #161616;
	}

	&__container {
		display: grid;
		place-items: center;
		border-bottom: 1px solid #FCFF71;
	}

	&__content{
		display: grid;
		grid-template-rows: 50px auto auto auto auto 18px 40px 22px;
		grid-gap: 16px;
		position: relative;

		width: 700px;
		padding: 35px 170px;

		.server-errors{
			&__container{
				display: grid;
				place-items: center;
			}
			&__content{
				color: #FCFF71;
				opacity: 0.5;
				font-weight: 600;
				font-size: 12px;
				line-height: 15px;
				letter-spacing: 0.06em;
				text-align: center;
			}
		}

		.vector{
			position: absolute;
			top: 0;
			left: 2%;
			pointer-events: none;
			width: 98%;
			height: 100%;

			&__top{
				position: absolute;
				top: 17px;
				left: 55px;
			}
			&__bottom{
				position: absolute;
				bottom: 15px;
				right: 30px;
			}
		}

		.close{
			position: absolute;
			top: 25px;
			right: 35px;
			height: 14px;
			width:14px;
		}
		.title{
			font-family: Airborne, source-code-pro, Roboto, sans-serif;
			font-size: 32px;
			line-height: 160%;
			text-align: center;
			letter-spacing: 0.04em;
			color: white;
		}
		.foreword{
			font-size: 14px;
			line-height: 160%;
			text-align: center;
			color: #A8A8A8;
		}
		.forgot-password{
			font-weight: 600;
			font-size: 14px;
			line-height: 18px;
			text-align: right;
			letter-spacing: 0.1em;
			text-transform: uppercase;
			color: #A8A8A8;
			margin: 0;
		}
		.create-account{
			display: flex;
			justify-content: space-between;
			font-size: 14px;
			line-height: 160%;
			text-align: center;
			color: #A8A8A8;

			& .ch-button{
				margin: 0;
				color: #25FFCB;
				line-height: 160%;
				height: fit-content;
			}
		}
		.border-button{
			svg.icon{
				width: 320px;
			}
		}
	}
}

@media screen and (max-width: 850px),
       screen and (max-height: 775px){
	.sign-in{
		grid-template-rows: 1fr;

		.close{
			position: absolute;
			top: 50px;
			right: 30px;
			height: 23px;
			width: 23px;
		}
		&__content{
			width: 100%;
			padding: 0 5%;
			position: initial;
		}
		&__container {
			border-bottom: none;
		}
		.footer-container, .vector, .vector__top, .vector__bottom{
			display: none;
		}
	}
}
