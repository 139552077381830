.learn-list{

	&__container{
		display: grid;
		grid-template-columns: repeat(auto-fill, minmax(290px, 1fr));
		grid-gap: 40px;
	}

	&__item{
		background: #161616;
		cursor: pointer;

		& .img {
			width: 100%;
			height: 180px;
			object-fit: cover;
		}
		& .description-container{
			padding: 15px;
			font-style: normal;
			height: calc(100% - 180px);
			display: grid;
			grid-template-rows: 40px 1fr auto;

			.title {
				font-weight: bold;
				font-size: 20px;
				line-height: 25px;
				color: #FFFFFF;
			}

			.description {
				font-weight: normal;
				font-size: 14px;
				line-height: 160%;
				color: #A8A8A8;
				padding-bottom: 15px;
			}
			.button-set {
				display: flex;
				flex-direction: revert;
				flex-wrap: wrap;
				gap: 10px;


				button {
					width: fit-content;
					line-height: 12px;
				}
			}
		}
	}
}
