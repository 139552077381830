@mixin text ($weight: normal) {
	font-family: source-code-pro, Roboto, sans-serif;
	font-style: normal;
	font-weight: $weight;
}

.security-container{
	display: grid;
	place-items: center;

	.title{
		@include text(bold);
		font-size: 20px;
		line-height: 25px;
		text-align: center;

		padding-top: 80px;
		padding-bottom: 32px;
	}

	.table {
		display: grid;
		place-content: center;
		gap: 8px;

		.table-row {
			min-height: 75px;
			display: grid;
			padding: 5px 16px;
			grid-template-columns: 300px 105px 120px 22px;
			grid-template-areas: "cell-1 cell-2 cell-3 cell-4" ;
			grid-gap: 32px;
			align-items: center;

			.table-cell{
				@include text();
				font-size: 14px;
				line-height: 160%;
				color: #A8A8A8;

				&.cell-1 { grid-area: cell-1	}
				&.cell-2 { grid-area: cell-2	}
				&.cell-3 { grid-area: cell-3	}
				&.cell-4 { grid-area: cell-4	}

				&:not(.table-cell.cell-1), &.cell-1 .location{
					opacity: 0.5;
				}

				&.cell-4 {
					display: flex;
					align-items: center;

					svg {
						fill: #A8A8A8;
						width: 12px;
						height: 14px;
					}
				}

				&.cell-1 .device {
					font-style: normal;
					font-weight: 600;
					font-size: 14px;
					line-height: 18px;
					letter-spacing: 0.1em;
				}
			}

			&.active {
				background: #202020;
				.table-cell {
					color: #25FFCB;

					&.cell-4 svg {
						fill: #25FFCB;
					}
				}
			}
		}
	}

	.terminate-sessions{
		padding-top: 32px;
		padding-bottom: 350px;
	}
}

@media screen and (max-width: 700px) {
	.security-container{

		.table{
			.table-row{
				grid-template-columns: 5fr 1fr;
				grid-template-areas: "cell-1 cell-2" "cell-3 . " ;
				min-height: 95px;
				grid-gap: 0 40px;

				&.header, .table-cell.cell-4{
					display: none;
				}

				.table-cell {
					font-size: 12px;
					&.cell-1 {
						.device{
							font-size: 12px;
						}
						.location, .device{
							padding-top: 5px;
						}
					}
					&.cell-2{
						height: 85%;
						line-height: 100%;
						text-align: right;
					}
					&.cell-3{
						padding-top: 2px;
						height: 22px;
					}
				}
			}
		}
	}
}
